<template>
  <div id="module-student">
    <CoolLightBox :items="coolLightImages" :index="indexViewImg" @close="indexViewImg = null" />
    <div class="card shadow filter" v-if="itemFilter">
      <div class="card-header p-1">
        <div class="card-title w-100">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fas fa-search"></i></span>
            </div>
            <input type="search" class="form-control" id="filter_name" placeholder="Tìm kiếm" v-model="itemFilter.name"
              v-on:keyup="keyEnter" autocomplete="off">
            <div class="input-group-append">
              <button type="button" class="btn btn-info btn-sm" v-on:click="showHideFilter(showFilter)"
                style="width: 40px;">
                <i class="fas fa-chevron-left" v-if="!showFilter"></i>
                <i class="fas fa-chevron-down" v-if="showFilter"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body pb-3" v-if="showFilter">
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <div class="form-group">
              <label class="control-label text-semibold">Tỉnh/Thành phố công tác</label>
              <b-form-select id="province" v-model="itemFilter.province_id" :options="provinceOptions">
              </b-form-select>
            </div>
          </div>
          <div class="col-lg-6 col-sm-12">
            <label class="control-label text-semibold">Ngày vào làm</label>
            <div class="row">
              <div class="form-group col-lg-5">
                <input type="date" class="form-control" v-model="itemFilter.from_date" id="from_date"
                  placeholder="Từ ngày" autocomplete="off">
              </div>
              <div class="text-sm text-center col-lg-2">~</div>
              <div class="form-group col-lg-5">
                <input type="date" class="form-control" v-model="itemFilter.to_date" id="to_date" placeholder="Đến ngày"
                  autocomplete="off">
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-sm-12">
            <div class="form-group">
              <label class="control-label text-semibold">Số điện thoại</label>
              <input type="number" class="form-control" v-model="itemFilter.phone_number" id="phone_number"
                placeholder="Số điện thoại" autocomplete="off" maxlength="11" v-on:keyup="keyEnter">
            </div>
          </div>
          <div class="col-lg-6 col-sm-12">
            <div class="form-group">
              <label for="filter_status">Tình trạng</label>
              <b-form-select id="filter_status" v-model="itemFilter.status" :options="filterStatusTeacherOptions"
                v-on:keyup="keyEnter">
              </b-form-select>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer" style="border-bottom: 1px solid rgba(0,0,0,.125);" v-if="showFilter">
        <div class="btn-group float-right">
          <button type="button" class="btn btn-info btn-sm" v-on:click="initItems()"><i
              class="fas fa-search mr-2"></i><span>Tìm kiếm</span></button>
          <button type="button" class="btn btn-default btn-sm" v-on:click="clearFilter()"><i
              class="fas fa-eraser mr-2"></i><span>Làm mới</span></button>
        </div>
      </div>
    </div>
    <div class="card shadow">
      <div class="card-body p-0">
        <table class="table size-website">
          <thead>
            <tr>
              <th style="width: 60px"></th>
              <th>
                <Order title="Tên" column="first_name" :callBack="orderBy" :orders="itemOrders" />
              </th>
              <th>
                <Order title="Liên hệ" column="phone_number" :callBack="orderBy" :orders="itemOrders" />
              </th>
              <th>
                <Order title="Tỉnh/Thành phố công tác " column="province_id" :callBack="orderBy" :orders="itemOrders" />
              </th>
              <th>
                <Order title="Tình trạng" column="status" :callBack="orderBy" :orders="itemOrders" />
              </th>
              <th>
                <Order title="Ngày vào làm" column="starting_date" :callBack="orderBy" :orders="itemOrders" />
              </th>
              <th>
                <Order title="Ngày tạo" column="created_at" :callBack="orderBy" :orders="itemOrders" />
              </th>
              <th style="width: 79px;">
                <button class="btn btn-success btn-sm btn-block" v-on:click="addItem()">
                  <i class="fas fa-plus-circle"></i>
                </button>
              </th>
            </tr>
          </thead>
          <tbody v-if="items && items.items">
            <tr v-for="(item, index) in items.items" :key="index">
              <td class="text-center">
                <b-avatar :src="fullPathFile(item.img)" class="elevation-2" size="38px" variant="default" />
              </td>
              <td>
                <router-link class="text-bold" v-bind:to="'/teacher/detail/' + item.id">
                  {{ item.first_name }} {{ item.last_name }}
                </router-link>
                <div class="text-sm"><i class="fas fa-birthday-cake mr-2"></i>{{ formatDate(item.dob) }}</div>
              </td>
              <td>
                <div>{{ formatPhone(item.phone_number) }}</div>
                <div><i class="text-sm">{{ item.address }}</i></div>
              </td>
              <td>
                <div>{{ item.stadium_name }}</div>
                <div>{{ getTextOption(item.province_id, provinceOptions) }}</div>
              </td>
              <td>{{ getTextOption(item.status.toString(), statusTeacherOptions) }}</td>
              <td class="text-center">{{ formatDate(item.starting_date) }}</td>
              <td class="text-center">{{ formatDate(item.created_at) }}</td>
              <td>
                <div class="btn-group">
                  <button class="btn btn-info btn-sm" v-on:click="updateItem(item)"><i class="fas fa-edit"></i></button>
                  <button class="btn btn-default btn-sm" v-on:click="deleteItem(item)"><i
                      class="far fa-trash-alt"></i></button>
                </div>
              </td>
            </tr>
          </tbody>
          <Paging v-if="items" :items="items" column="9" :callBack="pagingBy" />
        </table>
        <table class="table size-mobile">
          <thead>
            <tr>
              <th style="width: 60px">
                <button class="btn btn-success btn-sm btn-block" v-on:click="addItem()">
                  <i class="fas fa-plus-circle"></i>
                </button>
              </th>
              <th>
                <Order title="Giáo viên" column="first_name" :callBack="orderBy" :orders="itemOrders" />
              </th>
            </tr>
          </thead>
          <tbody v-if="items && items.items">
            <tr v-for="(item, index) in items.items" :key="index">
              <td class="text-center" v-on:click="indexViewImg = index">
                <b-avatar :src="fullPathFile(item.img)" class="elevation-2" size="38px" variant="default" />
              </td>
              <td>
                <div class="row">
                  <div class="col-md-6 col-sm-12">
                    <router-link class="text-bold" v-bind:to="'/teacher/detail/' + item.id">
                      {{ item.first_name }} {{ item.last_name }}
                    </router-link>
                    <span class="text-sm float-right">
                      {{ getTextOption(item.status.toString(), statusTeacherOptions) }}
                    </span>
                    <div class="text-sm"><i class="fas fa-birthday-cake mr-2"></i>{{ formatDate(item.dob) }}</div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <Paging v-if="items" :items="items" column="9" :callBack="pagingBy" />
        </table>
      </div>
    </div>

    <b-modal centered scrollable no-close-on-backdrop :id="modalIdTeacher"
      :title="itemChange && itemChange.id ? 'Cập nhật' : 'Thêm mới'" :size="'xl'">
      <div class="row admin-page" v-if="itemChange">
        <div :class="'col-sm-12'">
          <div class="row">
            <div class="col-lg-6 col-sm-12">
              <div class="form-group">
                <label class="control-label text-semibold label-valid">Họ & đệm</label>
                <input type="text" class="form-control" v-model="itemChange.first_name" id="first_name"
                  placeholder="Họ & đệm" autocomplete="off">
              </div>
            </div>
            <div class="col-lg-6 col-sm-12">
              <div class="form-group">
                <label class="control-label text-semibold label-valid">Tên</label>
                <input type="text" class="form-control" v-model="itemChange.last_name" id="last_name" placeholder="Tên"
                  autocomplete="off">
              </div>
            </div>
            <div class="col-lg-6 col-sm-12">
              <div class="form-group">
                <label class="control-label text-semibold label-valid">Ngày sinh</label>
                <input type="date" class="form-control" v-model="itemChange.dob" id="dob" placeholder="Ngày sinh"
                  autocomplete="off">
              </div>
            </div>
            <div class="col-lg-6 col-sm-12">
              <div class="form-group">
                <label class="control-label text-semibold label-valid">Số CMND/CCCD</label>
                <input type="number" class="form-control" v-model="itemChange.citizen_id" id="citizen_id"
                  placeholder="Số CMND/CCCD" autocomplete="off" maxlength="12">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-sm-12">
              <div class="form-group">
                <label class="control-label text-semibold">Số điện thoại</label>
                <input type="number" class="form-control" v-model="itemChange.phone_number" id="phone_number"
                  placeholder="Số điện thoại" autocomplete="off" maxlength="11">
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label class="control-label text-semibold">Địa chỉ</label>
                <input type="text" class="form-control" v-model="itemChange.address" id="address" placeholder="Địa chỉ"
                  autocomplete="off">
              </div>
            </div>
            <div class="col-lg-6 col-sm-12">
              <div class="form-group">
                <label class="control-label text-semibold label-valid">Ngày vào làm</label>
                <input type="date" class="form-control" v-model="itemChange.starting_date" id="starting_date"
                  placeholder="Ngày vào làm" autocomplete="off">
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label class="control-label text-semibold">Tỉnh/Thành phố công tác</label>
                <b-form-select id="province" v-model="itemChange.province_id" :options="provinceOptions">
                </b-form-select>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label class="control-label text-semibold">Ghi chú</label>
                <textarea class="form-control" rows="3" placeholder="Ghi chú" v-model="itemChange.note"></textarea>
              </div>
            </div>

            <div class="col-lg-6 col-sm-12">
              <div class="form-group">
                <label class="control-label text-semibold">Tình trạng</label>
                <b-form-select id="status" v-model="itemChange.status" :options="statusTeacherOptions">
                </b-form-select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <template #modal-footer>
        <button class="btn btn-info btn-sm" @click="saveItem(itemChange)">
          <i class="fas fa-save mr-2"></i><span>Lưu</span>
        </button>
        <button class="btn btn-default btn-sm" @click="hideModal(modalIdTeacher)">
          <i class="far fa-window-close mr-2"></i><span>Hủy</span>
        </button>
      </template>
    </b-modal>
  </div>
</template>
  
<script>
import ApiTeacher from "../../services/apiTeacher";
import { statusTeacherOptions } from "../../common/constants";
import { getAdminAuthen, getProvinces } from "../../common/storage";
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';

export default {
  components: {
    CoolLightBox,
    Paging: () => import("../components/Paging.vue"),
    Order: () => import("../components/Order.vue"),
  },
  data() {
    return {
      userLogin: null,
      coolLightImages: [],
      indexViewImg: null,
      showFilter: false,
      itemFilter: null,
      itemPaging: null,
      itemOrders: [{ column: "first_name", type: "asc" }],
      items: null,
      provinceOptions: [],
      modalIdTeacher: "teacher-modal",
      statusTeacherOptions: statusTeacherOptions,
      filterStatusTeacherOptions: statusTeacherOptions,
      itemChange: null,
      provinceItem: { value: "", text: 'Tất cả' },
    };
  },
  methods: {
    makeItemAddress() {
      return { province: "", district: "", stadium: "" };
    },
    makeItemFilter() {
      return { name: "", status: "", province_id: "", phone_number: "", from_date: "", to_date: "" };
    },
    showHideFilter(value) {
      this.showFilter = !value;
    },
    clearFilter() {
      this.itemFilter = this.makeItemFilter();
      this.initItems();
    },
    keyEnter: function (e) {
      if (e.which == 13) this.initItems();
    },
    async initItems() {
      this.showLoading(true);
      console.log("initItems data >> itemFilter ", this.itemFilter)
      const data = await ApiTeacher.getPaging(this.itemPaging, this.itemOrders, this.itemFilter);
      console.log("initItems data >> ", data)
      this.showLoading(false);
      // this.coolLightImages = [];
      if (data && data.items) {
        this.items = data;
        // this.items.items = data.items.map(e => {
        //   this.coolLightImages.push(this.fullPathFile(e.img));
        //   return e;
        // });
      }
    },
    pagingBy(paging) {
      this.itemPaging = paging;
      this.initItems();
    },
    orderBy(orders) {
      this.itemOrders = orders;
      this.initItems();
    },
    makeItem() {
      return { id: "", first_name: "", last_name: "", dob: "", status: "0", phone_number: "", citizen_id: "", address: "", starting_date: "", province_id: "", note: "" };
    },
    addItem() {
      this.itemChange = this.makeItem();
      this.showModal(this.modalIdTeacher);
    },
    async updateItem(item) {
      this.itemChange = this.setObjectValue(this.makeItem(), item);
      this.showModal(this.modalIdTeacher);
    },
    checkValid(item) {
      console.log("checkValid item", item);
      if (item) return this.checkValidForm([
        { id: "first_name", value: item.first_name, message: 'Vui lòng nhập Họ & đệm' },
        { id: "last_name", value: item.last_name, message: 'Vui lòng nhập Tên' },
        { id: "dob", value: item.dob, message: 'Vui lòng nhập Ngày sinh' },
        { id: "citizen_id", value: item.citizen_id, message: 'Vui lòng nhập CMND/CCCD' },
        { id: "starting_date", value: item.starting_date, message: 'Vui lòng nhập Ngày vào làm' },
      ]);
      return false;
    },
    async saveItem(item) {
      console.log("saveItem item", item);
      if (this.checkValid(item)) {
        this.showLoading(true);
        console.log("saveItem checkValid true item", item);
        const data = await ApiTeacher.addOrUpdate(item);
        console.log("saveItem addOrUpdate data", data);
        this.checkResponse(data, async () => {
          this.showLoading(false);
          this.hideModal(this.modalIdTeacher);
          this.initItems();
        }, () => { this.showLoading(false); });
      }
    },
    async deleteItem(item) {
      if (!item.id) return;
      this.confirmDelete(this.$route.name, `${item.first_name} ${item.last_name}`).then(async (result) => {
        if (!result) return;
        this.showLoading(true);
        const data = await ApiTeacher.delete(item.id);
        this.showLoading(false);
        this.checkResponse(data, () => {
          this.initItems();
        });
      })
    },
    async getProvince() {
      // this.provinceOptions = [this.loadingItem];
      const provinces = getProvinces();
      if (provinces && provinces.length > 0) this.provinceOptions = provinces.map(item => {
        return { value: item.province_id, text: `${item.type} ${item.name}` };
      });
      this.provinceOptions.unshift(this.provinceItem);
    }
  },
  mounted() {
    this.provinceOptions.unshift(this.provinceItem);
    this.filterStatusTeacherOptions.unshift({ value: "", text: 'Tất cả' });
    this.userLogin = getAdminAuthen();
    this.itemFilter = this.makeItemFilter(this.userLogin);
    this.initItems();
    this.getProvince();
    if (this.$route.query && this.$route.query.action == "add") {
      setTimeout(() => {
        this.addItem();
        this.$router.push({ path: '/teacher' });
      }, 500);
    }
  }
};
</script>
  