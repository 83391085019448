import DataService from "./dataService";
import config from "../common/config";
import { getPagingLimit } from "../common/storage";
import { getByPageOrder } from "../common/functions";

class ApiTeacher {
    _converFilter(filter) {
        var result = '';
        if (filter && filter.name) result += `&full_name=${filter.name}`;
        if (filter && typeof(filter.status) != 'undefined' && filter.status != "") result += `&status=${filter.status}`;
        if (filter && typeof(filter.province_id) != 'undefined' && filter.province_id != "") result += `&province_id=${filter.province_id}`;
        if (filter && filter.phone_number) result += `&phone_number=${filter.phone_number}`;
        if (filter && filter.from_date) result += `&from_date=${filter.from_date}`;
        if (filter && filter.to_date) result += `&to_date=${filter.to_date}`;
        return result;
    }
    getPaging(paging, orders, filter) {
        const limit = getPagingLimit();
        return DataService.get(`/teacher?${ getByPageOrder(paging, orders, limit ? limit : config.PER_PAGE) }${this._converFilter(filter)}`);
    }
    get(id) {
        return DataService.get(`/teacher_detail?id=${id}`);
    }
    addOrUpdate(data) {
        return DataService.post("/teacher", data);
    }
    delete(id) {
        return DataService.delete(`/teacher?id=${id}`);
    }
}

export default new ApiTeacher();